import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Layout } from '@/components/layouts/Layout'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '@/store/index'
import { navigate } from 'gatsby'
import { clearState } from '@/store/contactCompensation/action'
import { STATUS } from '@/constants/status'
import useFormInput from '@/hooks/useFormInput'
import axios from 'axios'
import { Seo } from '@/components/Seo'
import { BreadCrumbs } from '@/components/layouts/Breadcrumbs'
import { Title } from '@/components/elements/Title'
import { MainWrapper, TitleWrapper } from '@/styles/common'
import { theme } from '@/styles/theme'
import {
  FormMain,
  FormContent,
  FormSubGroup,
  FormSubItem,
  ConfirmFormAction,
  FileInfo,
} from '@/styles/form'
import { Button } from '@/components/elements/Button'
import { FormLabel } from '@/components/layouts/Form/FormLabel'
import { FormSubLabel } from '@/components/layouts/Form/FormSubLabel'

export default function compensationConfirm() {
  const breadCrumbs: {
    name: string
    path?: string
  }[] = [
    {
      name: 'トップ',
      path: '/',
    },
    {
      name: 'お問い合わせ',
      path: '/contact/',
    },
    {
      name: '入力内容のご確認',
    },
  ]

  const [isLoading, setIsLoading] = useState(false)

  const form = useSelector((state: RootState) => state.contactCompensation)
  const dispatch = useDispatch()
  const { dateParseString } = useFormInput()

  const handlePopstate = () => {
    history.pushState(null, '', null)
    dispatch(clearState())
  }

  useLayoutEffect(() => {
    const isReload = sessionStorage.getItem('isReload')
    if (isReload && isReload !== '') {
      navigate('/contact/compensation/')
    }
  }, [])

  const onUnload = () => {
    sessionStorage.setItem('isReload', '1')
  }

  useEffect(() => {
    window.addEventListener('popstate', handlePopstate, false)

    return () => {
      window.removeEventListener('popstate', handlePopstate, false)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('beforeunload', onUnload, false)

    return () => {
      sessionStorage.setItem('isReload', '')
      window.removeEventListener('beforeunload', onUnload, false)
    }
  })

  const modifyForm = () => {
    navigate('/contact/compensation/')
  }

  const sendForm = () => {
    setIsLoading(true)

    let fileArr: File[] = []
    if (form.other_files) {
      const otherFileArr: File[] = form.other_files
      fileArr = form.require_files.concat(otherFileArr)
    } else {
      fileArr = form.require_files
    }

    let submitData = new FormData()
    fileArr.map((file) => submitData.append('files[]', file))
    submitData.append('phone_number', form.tel)
    submitData.append('mail_address', form.email)
    submitData.append('record_date', dateParseString(form.time_of_notice))
    submitData.append('occurred_date', dateParseString(form.time_of_emergence))
    submitData.append('officer', form.police)
    submitData.append('request_code', form.receipt_number)
    submitData.append('is_using_supay', form.is_use_supay)
    if (form.is_use_supay === STATUS.YES) {
      submitData.append('supay_balance', form.balance)
      submitData.append('bank_name', form.bank)
    }
    submitData.append('loss_amount', form.total_damage)
    submitData.append('is_receive_money_from_other', form.has_compensation)
    form.detail && submitData.append('description', form.detail)

    axios
      .post('/compensation', submitData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        dispatch(clearState())
        setIsLoading(false)
        if (response.status == 200) {
          navigate('/contact/compensation/done/')
        } else {
          navigate('/contact/error/')
        }
      })
      .catch((e) => {
        setIsLoading(false)
        navigate('/contact/error/')
      })
  }

  return (
    <Layout isLoading={isLoading} isForm>
      <Seo
        title="お問い合わせ内容確認 - お問い合わせ"
        canonical="https://su-pay.jp/contact/compensation/confirm/"
        noIndex
      />
      <Title as="h1" bg={true}>
        お問い合わせ
      </Title>
      <MainWrapper>
        <BreadCrumbs breadCrumbs={breadCrumbs} />
        <TitleWrapper>
          <Title as="h2" color={theme.colors.supayBlue}>
            入力内容のご確認
          </Title>
        </TitleWrapper>
        <FormMain>
          <FormLabel isRequired>利用している電話番号</FormLabel>
          <FormContent>
            <p>{form.tel}</p>
          </FormContent>
          <FormLabel isRequired>返信先メールアドレス</FormLabel>
          <FormContent>
            <p>{form.email}</p>
          </FormContent>
          <FormLabel isRequired>被害に気づかれた時期</FormLabel>
          <FormContent>
            <p>{dateParseString(form.time_of_notice)}</p>
          </FormContent>
          <FormLabel isRequired>被害発生時期</FormLabel>
          <FormContent>
            <p>{dateParseString(form.time_of_emergence)}</p>
          </FormContent>
          <FormLabel isRequired>相談した警察署・担当者名</FormLabel>
          <FormContent>
            <p>{form.police}</p>
          </FormContent>
          <FormLabel isRequired>警察への被害届の受理番号</FormLabel>
          <FormContent>
            <p>{form.receipt_number}</p>
          </FormContent>
          <FormLabel isRequired>SU-PAYを利用しているか</FormLabel>
          <FormContent>
            <FormSubGroup>
              <FormSubItem>
                <p>{form.is_use_supay === STATUS.YES ? 'はい' : 'いいえ'}</p>
              </FormSubItem>
              {form.is_use_supay === STATUS.YES && (
                <>
                  <FormSubItem>
                    <FormSubLabel>被害が発生しているSU-PAY残高</FormSubLabel>
                    <p>{form.balance} 円</p>
                  </FormSubItem>
                  <FormSubItem>
                    <FormSubLabel>被害が発生している銀行名</FormSubLabel>
                    <p>{form.bank}</p>
                  </FormSubItem>
                </>
              )}
            </FormSubGroup>
          </FormContent>
          <FormLabel isRequired>対象被害総額</FormLabel>
          <FormContent>
            <p>{form.total_damage} 円</p>
          </FormContent>
          <FormLabel isRequired>
            当社以外から今回の被害に関する補償を受けたか、または受ける予定があるか
          </FormLabel>
          <FormContent>
            <p>{form.has_compensation === STATUS.YES ? 'はい' : 'いいえ'}</p>
          </FormContent>
          <FormLabel isRequired>
            SU-PAY補償制度に関する規約を確認しました
          </FormLabel>
          <FormContent>
            <p>はい</p>
          </FormContent>
          {form.detail && (
            <>
              <FormLabel>SU-PAY申請内容の詳細</FormLabel>
              <FormContent>
                <p>{form.detail}</p>
              </FormContent>
            </>
          )}
          <FormLabel isRequired>本人確認書類</FormLabel>
          <FormContent>
            {form.require_files.map((file, i) => (
              <FileInfo key={`${file.name}${i}`}>
                <p>{file.name}</p>
              </FileInfo>
            ))}
          </FormContent>
          {form.other_files.length > 0 && (
            <>
              <FormLabel>その他の書類</FormLabel>
              <FormContent>
                {form.other_files.map((file, i) => (
                  <FileInfo key={`${file.name}${i}`}>
                    <p>{file.name}</p>
                  </FileInfo>
                ))}
              </FormContent>
            </>
          )}
        </FormMain>
        <ConfirmFormAction>
          <Button showArrowLeft bgColor="#D0D5DB" onClick={() => modifyForm()}>
            戻る
          </Button>
          <Button showArrowRight onClick={() => sendForm()}>
            送信する
          </Button>
        </ConfirmFormAction>
      </MainWrapper>
    </Layout>
  )
}
